@font-face {
    font-family: Roboto;
    font-weight: 900;
    src:
        local('Roboto-Black'),
        url('../../assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 900;
    src:
        local('Roboto-BlackItalic'),
        url('../../assets/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src:
        local('Roboto-Bold'),
        url('../../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 700;
    src:
        local('Roboto-BoldItalic'),
        url('../../assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    src:
        local('Roboto-Italic'),
        url('../../assets/fonts/roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src:
        local('Roboto-Light'),
        url('../../assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    src:
        local('Roboto-LightItalic'),
        url('../../assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src:
        local('Roboto-Medium'),
        url('../../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    src:
        local('Roboto-MediumItalic'),
        url('../../assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src:
        local('Roboto-Regular'),
        url('../../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    src:
        local('Roboto-Thin'),
        url('../../assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 100;
    src:
        local('Roboto-ThinItalic'),
        url('../../assets/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
}
