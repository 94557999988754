@import '../../../../../styles/variables/colors';

.spinner-wrapper {
    align-items: center;
    background-color: $colorWhite;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: .7;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    > span {
        > svg {
            color: $colorPrimary;
        }
    }
}
