@import '../../../../../styles/variables/colors';
@import '../../../../../styles/mixins/breakpoint';

.login-page {
    align-items: center;
    display: flex;
    height: 100vh;
    min-height: 680px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        background-image: url('../../../../../assets/img/logo/background.svg');
        background-position: left top;
        background-repeat: no-repeat;
        justify-content: flex-end;

        .wrapper-login-container {
            min-width: 50%;
            width: calc(100% - 800px);

            .login-container {
                margin: 0 auto;
                max-width: 565px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        justify-content: center;

        .login-container {
            margin: 0 auto;
            max-width: calc(100% - 20px);
        }
    }

    @include media-breakpoint-down(sm) {
        .wrapper-login-container {
            padding: 2rem;

            .login-form .submit-form-buttons > div {
                width: 100%;

                + div {
                    margin-left: 0;
                    margin-top: 1rem;
                }
            }
        }
    }

    .login-header {
        .login-logo {
            margin-bottom: 40px;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .login-text {
            color: $colorPrimary;
            text-align: center;

            .title {
                font-size: 2.6rem;
                font-weight: $fontMedium;
            }
        }
    }

    .login-form {
        margin: 0 auto;
        max-width: 420px;
        position: relative;
        text-align: center;
        width: 100%;

        .login-form-input-container {
            display: flex;
            flex-direction: column;
            padding: 1rem 0;

            > label {
                align-self: start;
                margin-bottom: .2rem;
            }

            > svg {
                margin: 20px;
                position: absolute;
                right: 0;
            }
        }

        .forget-form-button {
            margin-top: 22px;
            text-align: right;

            a {
                font-weight: $fontMedium;
            }
        }

        .submit-form-buttons {
            margin-top: 30px;

            > div {
                display: inline-block;
                padding: 0;
                width: calc(50% - 5px);

                + div {
                    margin-left: 10px;
                }

                button {
                    width: 100%;
                }
            }
        }

        .login-term-use {
            display: flex;
            flex-direction: row;
            margin-top: 50px;
            text-align: left;

            a {
                font-weight: $fontBold;
            }

            label {
                align-items: stretch;
                cursor: pointer;
                margin-left: 1rem;

                #accept-checkbox {
                    display: flex;
                    margin-right: 20px;
                    margin-top: -20px;
                    width: 20px;
                }
            }
        }

        .custom-error-message {
            margin-top: 15px;
        }
    }
}
