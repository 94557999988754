.beauty-scroll {
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $backgroundColorScrollbarThumb;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $colorWhite;
        box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: $backgroundColorScrollbarThumbActive;
    }

    &::-webkit-scrollbar-track {
        background: $backgroundColorScrollbarTrack;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
        background: $backgroundColorScrollbarTrackHover;
    }
}
