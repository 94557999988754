$colorBlack: #000000;
$colorWhite: #FFFFFF;
$colorAlert: #8B0000;
$colorPrimary: #003883;
$colorSecondary: #EE7B11;
$colorGrey: #888888;
$colorGreyLight: #F7F9FB;
$colorLabel: #45515D;
$inputBorder: #E1E1E1;
$colorBase: #34434A;
$colorLinkSidebar: #9AA2A6;
$backgroundColorSidebar: #F4F5F9;

$backgroundColorScrollbarThumb: #CCCCCC;
$backgroundColorScrollbarThumbHover: #B3B3B3;
$backgroundColorScrollbarThumbActive: #999999;
$backgroundColorScrollbarTrack: #E1E1E1;
$backgroundColorScrollbarTrackHover: #D4D4D4;

$backgroundColorEvenRowTable: #F7F7F7;

$backgroundLabel: #34434A26;
$colorLabel: #34434A;

$backgroundColorSampleAnalyzedStatus: #293C4F;
$backgroundColorSampleRegisteredStatus: #7F8694;
$backgroundColorSampleValidatedStatus: #EE7B11;
$backgroundColorSampleWithAlertsStatus: #E63632;

$backgroundColorAlertDefault: #7F8694;
$backgroundColorAlertAlertType: #EE7673;
$backgroundColorAlertResultType: #F4A457;

$backgroundColorSwitcherSliderOff: #C3C7C9;
$backgroundColorCircleSwitcherSliderOff: #F1F1F1;
$backgroundColorSwitcherSliderOn: #7C98BE;
$backgroundColorCircleSwitcherSliderOn: #003584;

$separatorColor: #F0F0F0CF;

$backgroundColorNumberItemsShoppingCart: #314149;
$backgroundColorItemsShoppingCartList: #F7F9FB;

$backgroundColorAlertWarning: #F4A457;
$backgroundColorAlertDangerous: #EE7673;

$backgroundColorMessageCreated: #1ACA98;

$backgroundColorAlertSampleTypeResult: #EA7370;
$backgroundColorAlertSampleTypeAlert: #F0A155;

$backgroundColorIncidence: #EA7370;

$profileEmailColor: #7F8694;

$backgroundColorNotificationTypeResult: #F0A155;
$backgroundColorNotificationTypeAlert: #EA7370;
$backgroundColorNotificationTypeLegislative: #EA7370;
$backgroundColorNotificationTypeArrival: #F0A155;

$backgroundColorInputDisabled: #B3B3B3;

$backgroundDraftNotification: #F0A15533;
$backgroundPresampleNotification: #00388233;

$backgroundColorDraftType: #F0A155;

$validatedPresampleDate: #7F8694;

$smallCluster: #1BAC62;
$averageCluster: #F29845;
$bigCluster: #FF0600;

$redColor01: #E53935;
$disclaimerTextColor: $redColor01;
$disclaimerIconColor: $redColor01;
$disclaimerBackgroundColor: #FAEDED;

$tabText: #7F8694;
$tabTextFocused: #003883;
$tabBorderBottom: #7F8694;
$tabButtonsBorderBottom: #003883;

$containerRequestByBudgetListEmpty: #F7F7F7;
